import { render, staticRenderFns } from "./House.vue?vue&type=template&id=8974250e&scoped=true"
import script from "./House.vue?vue&type=script&lang=js"
export * from "./House.vue?vue&type=script&lang=js"
import style0 from "./House.vue?vue&type=style&index=0&id=8974250e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8974250e",
  null
  
)

/* custom blocks */
import block0 from "./House.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports